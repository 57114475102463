/* Typography */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=Inter:wght@400;600;700&family=Roboto+Flex:opsz,wght@8..144,600&family=Roboto:wght@400;500;700&family=Anton&display=swap');


h1,h2,h3,h4,h5,h6,p {
  margin: 0.5rem auto;
  color: #000;
}


h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 3.75em;
  line-height: 5.25rem;
}

h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.8rem;
}

h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.8rem;
}

h4,
summary,
.error span {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 1.4rem;
  letter-spacing: 0.2px;
}

p,
input {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.5rem;
  letter-spacing: 0.2px;
}

label {
  color: var(--voilet);
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 1.12em;
  line-height: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h5 {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5rem;
}

h6,
.h6-text {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.87em;
  line-height: 1rem;
}

button,
.button-text {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 0.87em;
  line-height: 1.25rem;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.support-text {
  text-align: center;
  color: var(--voilet) !important;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 0.8em;
  line-height: 1.25rem;
  letter-spacing: 0.1em;
  margin: 0.5rem auto !important;
}

.bold {
  font-weight: 700;
}

/* Colors */
:root {
  --bright: #8E15CC;
  --light: #9C6BFE;
  --extra-light: #C4A6FF;
  --black: #000;
  --extra-dark: #2A063D;
  --dark: #111;
  --dark-gray: #111827;
  --white: #fff;
  --light-gray: #EAEAEA;
  --voilet: #6F6C90;;
  --gray: #A3A3A3;
  --light-red: #FFC7C7;
  --red: #FF0000;
  --neutral: #EFF0F6;
  --transparent: transparent;

  --eco-bank: #BED600;
  --uba-bank: #ED1500;
  --coris-bank: #00539F;
  --orange-money: #FF6600;
  --moov-money: #E8690A;
  --mtn-money: #E9BC33;
}

/* Buttons */

.primary-button {
  width: 100%;
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 0.375rem;
  color: var(--white);
  background-color: var(--bright);
  transition: all 0.6s ease;
}

.primary-button:disabled {
  background-color: var(--light-gray) !important;
}

.secondary-button {
  width: 100%;
  padding: 0.625rem 1.25rem;
  border: 1px solid var(--black);
  border-radius: 0.375rem;
  color: var(--black);
  background-color: transparent;
  transition: all 0.6s ease;
}

.secondary-button:hover {
  color: var(--light);
  border-color: var(--light);
}

.secondary-button:disabled {
  color: var(--light-gray) !important;
  border-color: var(--light-gray) !important;
}

.icon-button {
  border: none;
  border-radius: 0.375rem;
  background-color: transparent;
  transition: all 0.6s ease;
}

.icon-button:hover {
  fill: var(--light);
}

.icon-button:disabled {
  fill: var(--gray);
}

.back-button svg {
  margin-bottom: clamp(-3px, -0.04vw, 6px);
  height: 100%;
  fill: currentColor;
}

.back-button {
  padding: 0px;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 1.75em;
  line-height: 1.25rem;
  border: none;
  text-decoration: none;
  color: var(--black);
  background-color: var(--transparent);
  transition: all 0.6s ease;
}


.back-button:hover {
  color: var(--light);
}

/* Button Loaders */

.btn-loader {
  height: 1.5rem;
}

/* Expandable */

details {
  cursor: pointer;
  position: relative;
  padding: 0.8rem 1.25rem;
  border-radius: 0.75rem;
  background-color: var(--neutral);
  transition: all 0.6s ease;
}

details > summary {
  list-style-type: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

details > summary::after {
  content: ' ↩';
  position: absolute;
  right: 1.5%;
}

details[open] > summary::after {
  content: '  ^';
}

/* Note */

.note {
  padding: 1.25rem;
  border-radius: 0.75rem;
  background-color: var(--light-red);
}

.note h6 {
  margin: 0.7rem auto;
}

/* Card */

.card {
  cursor: pointer;
  text-align: center;
  width: 15.5rem;
  padding: 2rem 0px;
  border-radius: 1.5rem;
  background-color: var(--light-gray);
  transition: all 0.6s ease;
}

.card:hover {
  color: var(--white);
  background-color: var(--light);
}

.card:hover h3,.card:hover svg {
  filter: invert(1);
}

/* Form Controls */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-item {
  position: relative;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
}

.form-item label {
  display: block;
  margin-bottom: 0.6rem;
}

.form-item svg {
  margin-bottom: -0.3rem;
  margin-left: -2.7rem;
}

.form-item .icon-button {
  position: absolute;
  top: 2.6rem;
  right: 3px;  
} 

.input-control {
  padding: 1.3rem 1rem;
  border: none;
  border-radius: 0.75rem;
  background-color: var(--light-gray);
}

.input-control::placeholder {
  color: var(--gray);
}

.input-control:focus-visible {
  outline: none;
}

.input-control.full-width {
  width: -webkit-fill-available;
}

.error .input-control {
  background-color: var(--light-red);
}

.error span {
  display: inherit;
  margin-left: 0.3rem;
  margin-top: 0.5rem;
  color: var(--red);
}

/* PopUp */
.pop-up-continer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transition: all 0.6s ease;
}


.pop-up {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22.32rem;
  min-height: 14rem;
  padding: 2rem 3rem;
  text-align: center;
  border-radius: 1.5rem;
  background-color: var(--extra-dark);
}

.pop-up .inner-content > * {
  margin: 1rem auto;
  color: var(--white);
}

.close-button {
  position: absolute;
  top: 0.5rem;
  left: 0.2rem;
  scale: 0.7;
}

.pop-up-img {
  margin: 0px !important;
  width: 7rem;
}

/* Count Down */

.count-down h4 {
  text-align: center;
  color: var(--white);
  font-family: 'Anton','Roboto', sans-serif;
  font-size: 9.35em;
  line-height: 9.35rem;
  letter-spacing: 5px;
}

/* Summary */

.payment-summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
}

.summary-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0.625rem auto;
}

.summary-value,
.summary-label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin: 0;
}

.summary-label {
  font-weight: 500;
  text-transform: uppercase;
}

.summary-value {
  font-weight: 400;
}



/* pages */

.main {
  display: flex;
  max-width: 90rem;
  height: 60rem;
  width: 100%;
  margin: auto;
  background-color: var(--white);
  box-shadow: -10px 5px 40px rgba(0, 0, 0, 0.1);
}

.half.first {
  overflow-y: scroll;
  flex: 1.117;
}

.half.second {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.half.second img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.continer {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
}

header {
  margin-top: 2rem;
  padding-left: 10%;
  padding-right: 10%;
}

.brand {
  width: 6.5rem;
}

main {
  margin: 4% auto;
  padding: 2rem 10%;
  min-height: 75%;
}

ul {
  padding-left: 1.5rem;
}

.page-title {
  margin: 2.6rem 0;
}

.wallets {
  display: flex;
  flex-wrap: wrap;
}

.wallets > * {
  margin: 1rem 2rem 1rem 0px;
}

form .primary-button {
  margin-top: 1.5rem;
}

.phone-number {
  color: var(--light);
  font-weight: bold;
}

.otp-note {
  margin: 2rem auto;
}

.ussd-input {
  position: relative;
}

.copy-right {
  display: block;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--gray);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
}

/* 4K */
@media screen and (min-width: 5000px) {
  .main {
    max-width: 85rem;
    min-height: 60vh;
    box-shadow: -10px 5px 40px rgba(0, 0, 0, 0.1);
  }
}

/* Mobile */
@media screen and (max-width: 480px) {

  main { 
    padding: 2rem 5%;
  }

  .main {
    position: relative;
    flex-direction: column-reverse;
    height: auto;
    min-height: 100vh;
  }

  header {
    position: absolute;
    top: 0;
  }

  .back-button {
    position: absolute;
    top: 8rem;
  }

  .half.second {
    margin-top: 12.5rem;
    max-height: 30vh;
  }

  .half.second img, .half.second .content {
    max-height: 15rem;
  }

  .content svg {
    height: 100%;
  }

  .wallets > * {
    margin: 1rem auto;
  }

  /* ********************** */

  h3 {
    font-size: 1.5em;
  }

  h5 {
    font-size: 1.1em;
  }

  /* Buttons */

  button, .button-text {
    font-size: 1.5rem;
  }

  .primary-button {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
  }

  .card {
    width: 100%;
    padding: 1.5rem;
  }

  .inner-items {
    display: flex;
    align-items: center;
  }

  .inner-items > h3 {
    width: 100%;
    text-align: left;
    margin-left: 0.8rem;
  }

}
 