#noteOne{
    animation: noteOnePopping 0.6s 0.1s forwards cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes noteOnePopping {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-75px);
    }
}

#noteTwo{
    animation: noteTwoPopping 0.6s 0.1s forwards cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes noteTwoPopping {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-45px);
    }
}

/* 404 not found */

#sun {
    animation: sunMoving 2s 0.1s forwards ease-out;
  }
  
  @keyframes sunMoving {
    100% {
      transform: translate(-120px, -15px);
    }
  }
  
  #cloudTwo,
  #cloudOne {
    animation: cloudsFloating 0.8s 0.1s infinite alternate ease-out;
  }
  
  @keyframes cloudsFloating {
    100% {
      transform: translateY(5px);
    }
  }
  
  #reflection {
    color: white;
    animation: reflectingColors 2s 0.1s forwards linear;
  }
  
  @keyframes reflectingColors {
    100% {
      color: #f2f2f2;
    }
  }

  /* paying hand */
  #handHoldingCard {
    animation: giving 0.6s 0.1s forwards cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  @keyframes giving {
    0% {
      transform: translateX(-100px);
      transform: rotate(0.1277rad);
    }
  
    100% {
      transform: translateX(0px);
      transform: rotate(0rad);
    }
  }
  
  #handGrabingCard {
    animation: receiving 0.6s 0.1s forwards cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  @keyframes receiving {
    0% {
      transform: translate(100px, -20px);
    }
  
    100% {
      transform: translate(0px, 0px);
    }
  }
  
  #thumb {
    animation: receiving 0.6s 0.1s forwards cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  #creditCard {
    animation: cardRotating 0.6s 0.1s forwards
      cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  
  @keyframes cardRotating {
    0% {
      transform: rotate(0.1277rad);
    }
  
    100% {
      transform: rotate(0rad);
    }
  }