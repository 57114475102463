html {
  font-size: clamp(10px, 0.8vw, 30px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--neutral);
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0.3rem;
}

::-webkit-scrollbar-thumb {
  background: var(--neutral);
  border-style: solid;
  border-color: var(--neutral);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
  border-color: var(--gray);
}

/* second half animated svgs */
#summary-svg,
#not-found-svg {
  width: 100%;
  background-color: var(--neutral);
}

#wallet-svg {
  width: 80%;
}

@media screen and (max-width: 480px) {

  html {
    font-size: clamp(11px, 0.8vw, 30px);
  }

  #wallet-svg {
    width: 65%;
  }
}